const createText = (text, attrs) => {
  const textDiv = document.createElement('div');
  attrs = {
    ...attrs,
    position: 'absolute',
    top: '-100000px',
    left: '-100000px',
  };
  Object.keys(attrs).forEach((attr) => {
    attrs[attr.replace(/\B([A-Z])/g, '-$1').toLowerCase()] = attrs[attr];
    textDiv.style[attr] = attrs[attr];
  });

  textDiv.innerHTML = text;
  // document.querySelector('.zn--wrapper').appendChild(textDiv);
  document.body.appendChild(textDiv);
  return {
    width: Math.ceil(textDiv.getBoundingClientRect().width),
    height: Math.ceil(textDiv.getBoundingClientRect().height),
  };
};

export function getTextPixels(
  text,
  { gap = 1, fontSize = '100px', fontFamily = 'OPPOSans-Bold' } = {},
) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  let pixelsData = [];

  return new Promise((resolve, reject) => {
    // 此方法ie不支持
    document.fonts
      .load(`${fontSize} ${fontFamily}`, 'test')
      .then(() => {
        const { width, height } = createText(text, { fontSize, fontFamily });
        canvas.width = width;
        canvas.height = height;
        canvas.style.width = `${width / 2}px`;
        canvas.style.height = `${height / 2}px`;

        ctx.fillStyle = '#FFFFFF';
        ctx.fillRect(0, 0, width, height);

        ctx.font = `${fontSize} ${fontFamily}`;
        ctx.fillStyle = 'black';
        ctx.textAlign = 'center';
        ctx.textBaseline = 'middle';
        ctx.fillText(text, width / 2, height / 2);

        const { data: pixels } = ctx.getImageData(0, 0, width, height);
        for (let x = 0; x < width; x += gap) {
          for (let y = 0; y < height; y += gap) {
            const i = (x + y * width) * 4;
            // 判断条件根据情况判定 （当前情况：白底黑字拿黑字）
            if (pixels[i] < 5 && pixels[i + 1] < 5 && pixels[i + 2] < 5) {
              pixelsData.push({
                pos: [x, y, 0],
                color: pixels.slice(i, i + 4),
              });
            }
          }
        }
        resolve({ pixelsData, size: { width, height } });
      })
      .catch((e) => reject(e));
  });
}

// export function getTextPixels(src, { gap = 1, width = 770 } = {}) {
//   const canvas = document.createElement('canvas');
//   const ctx = canvas.getContext('2d');
//   const image = new Image();

//   let height;
//   let pixelsData = [];

//   return new Promise((resolve) => {
//     image.src = src;
//     image.onload = () => {
//       height = Math.ceil(width * (image.height / image.width));
//       canvas.width = width;
//       canvas.height = height;
//       ctx.clearRect(0, 0, width, height);
//       ctx.drawImage(image, 0, 0, width, height);

//       const { data: pixels } = ctx.getImageData(0, 0, width, height);
//       for (let x = 0; x < width; x += gap) {
//         for (let y = 0; y < height; y += gap) {
//           const i = (x + y * width) * 4;
//           // 判断条件根据情况判定 （当前情况：白底黑字拿黑字）
//           if (pixels[i] < 10 && pixels[i + 1] < 10 && pixels[i + 2] < 10) {
//             pixelsData.push({
//               pos: [x, y, 0],
//               color: pixels.slice(i, i + 4),
//             });
//           }
//         }
//       }

//       resolve({
//         pixelsData,
//         size: { width, height },
//       });
//     };
//   });
// }
