<template lang="pug">
video(id="localVideo" ref='video' autoplay playsinline controls="false")
canvas.canvas(ref='canvas')
</template>

<script>
window.__DEBUG__ = false;

import createREGL from 'regl';
import { onBeforeUnmount, onMounted, ref } from 'vue';
import { createTextCommand } from '@/commands/star-text-z';
import clamp from 'lodash.clamp';

async function playVideoFromCamera() {
  try {
    const constraints = { audio: false, video: { facingMode: { exact: 'environment' } } };
    const stream = await navigator.mediaDevices.getUserMedia(constraints);
    const videoElement = document.querySelector('video#localVideo');
    videoElement.srcObject = stream;
  } catch (error) {
    console.error('Error opening video camera.', error);
  }
}

export default {
  name: 'ARText',
  setup() {
    const canvas = ref(null);

    /** @type {ReturnType<typeof createREGL>} */
    let regl;

    onMounted(async () => {
      await playVideoFromCamera();
      document.getElementById('localVideo').style.opacity = 1;

      const onResize = () => {
        canvas.value.width = window.innerWidth * 2;
        canvas.value.height = window.innerHeight * 2;
      };
      window.addEventListener('resize', onResize);
      onResize();

      regl = createREGL({
        canvas: canvas.value,
      });

      const drawMain = await createTextCommand(regl, 'RENO5');
      const setup = regl({
        depth: { enable: true },
      });
      const update = ({ time }) => {
        let p = clamp(time / 4, 0, 1);
        setup({}, () => {
          regl.clear({
            color: [0, 0, 0, 0],
            depth: 1,
          });
          drawMain({ p });
        });
      };
      regl.frame(update);
    });

    onBeforeUnmount(() => regl?.destroy());

    return { canvas };
  },
};
</script>

<style lang="stylus" scoped>
*
  box-sizing border-box

.fixed
  position fixed
  left 0
  top 0

.absolute
  position absolute
  left 0
  top 0

.stats
  z-index 2
  color #fff
  width 100%
  padding 0 10px
  display flex
  justify-content space-between
  color cyan

.p-controller
  width 100%
  height 500vh

video
  z-index 0
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  opacity 0
  background-color black

.canvas
  z-index 1
  position fixed
  top 0
  left 0
  width 100%
  height 100%
  background-color transparent
  cursor pointer

  img
    display none

.tips
  width 100%
  display flex
  align-items flex-end
  justify-content center
  pointer-events none
  top auto
  bottom 0
  color yellow
</style>
