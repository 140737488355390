import vert from './text.vert';
import frag from './text.frag';
import mat4 from 'gl-mat4';
// import src from '../../assets/reno.jpg';
import { getTextPixels } from './getTextInfo';

export async function createTextCommand(regl, text = 'starry night') {
  const {
    pixelsData,
    size: { width, height },
  } = await getTextPixels(text, { fontSize: '50px', fontFamily: 'Helvetica', gap: 1 });

  // 增加坐标的 z 轴厚度

  const pixelsDataZ = [];
  pixelsData.forEach(({ pos }) => {
    for (let z = -0.5; z <= 0.5; z += 0.1) {
      pixelsDataZ.push([...pos, z]);
    }
  });

  // 像素起始随机坐标
  const originPosition = pixelsDataZ.map(() => {
    const alpha = Math.random() * 2 * Math.PI;
    const z = Math.random() * 2 * Math.PI;
    const r = Math.random();
    return [r * Math.sin(alpha), r * Math.cos(alpha), r * Math.sin(z)].map((n) => n * 20);
  });

  // 文字像素坐标
  const finalPosition = pixelsDataZ.map((pos) => [
    (pos[0] * 2) / width - 1 + (Math.random() - 0.5) * 0.01,
    (-pos[1] * 2) / height + 1 + (Math.random() - 0.5) * 0.01,
    pos[2],
  ]);

  return regl({
    vert,
    frag,
    attributes: {
      a_finalPosition: finalPosition,
      a_originPosition: originPosition,
    },
    uniforms: {
      p: regl.prop('p'),
      u_t: ({ time }) => time,
      u_model: ({ viewportWidth, viewportHeight, pixelRatio }) =>
        mat4.scale([], mat4.create(), [
          (width / viewportWidth) * pixelRatio,
          (height / viewportHeight) * pixelRatio,
          1,
        ]),
      view: ({ tick }) => {
        const t = 0.01 * tick;
        return mat4.lookAt(
          [],
          [5 * Math.cos(t), 2.5 * Math.sin(t), 5 * Math.sin(t)],
          [0, 0.0, 0],
          [0, 1, 0],
        );
      },
      projection: ({ viewportWidth, viewportHeight }) =>
        mat4.perspective([], Math.PI / 4, viewportWidth / viewportHeight, 0.01, 10),
    },
    count: pixelsDataZ.length,
    primitive: 'points',
  });
}
